<template>
  <div>
    <div class="header">
      <span>用户信息</span>
    </div>
    <div class="nav">
      <!-- <p style="color:#333333">权限说明</p>
      <p class="common">1.管理员账号拥有所有模块的权限</p>
      <p class="common">2.普通账号在【交易订单】模块中只拥有提交权限；管理员账号拥有【交易订单】模块中的提交、复核及付款权限；</p>
      <p class="common">3.普通账号没有【权限管理】模块的权限；管理员账号拥有【权限管理】模块的权限。</p> -->
    </div>
    <div class="opt">
      <a-button type="primary" @click="visibleAdd=true">添加员工账户</a-button>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="raskTable"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
        :scroll="{ x: 800 }"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="freeze(record)" v-if="record.status==='100201'">冻结员工</a>
          <a @click="refreeze(record)" v-else>解冻员工</a>
          <a-divider type="vertical" />
          <a @click="reset(record)">重置密码</a>
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="添加员工账户"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <div class="radio-choose">
        <a-radio-group v-model="radioValue" @change="radioChange">
          <a-radio :value="100701">
            普通账户
          </a-radio>
          <a-radio :value="100702">
            邮箱账户
          </a-radio>
        </a-radio-group>
  </div>
      <div v-if="radioValue === 100701">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
          <a-form-item label="姓名">
          <a-input
            placeholder="请填写姓名"
            v-decorator="['userName', { rules: [{ required: true, message: '请填写姓名!' }] }]"
          />
        </a-form-item>
        <a-form-item label="账号">
          <a-input
            placeholder="请填写账号"
            v-decorator="['accountName', { rules: [{ required: true, message: '请填写账号!' },{ min: 6, message: '长度最少6位', trigger: 'blur' },{ max: 20, message: '长度最多20位', trigger: 'blur' },] }]"
          />
        </a-form-item>
        <a-form-item label="密码">
          <a-input
            placeholder="请填写密码"
            v-decorator="['passWord', { rules: [{ required: true, message: '请填写密码!' },{ min: 6, message: '长度最少6位', trigger: 'blur' },{ max: 20, message: '长度最多20位', trigger: 'blur' }] }]"
          />
        </a-form-item>
       
        
      </a-form>
      </div>
      <div v-if="radioValue === 100702">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="邮箱">
          <a-input
            placeholder="请填写邮箱"
            v-decorator="['email', { rules: [{ required: true, message: '请填写邮箱!' }] }]"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            placeholder="请填写姓名"
            v-decorator="['userName', { rules: [{ required: true, message: '请填写姓名!' }] }]"
          />
        </a-form-item>
        <a-form-item label="权限类型">
          <a-select
            v-decorator="[
              'roleType',
              { rules: [{ required: true, message: '请选择!' }] },
            ]"
            placeholder="请选择权限类型"
            @change="handleSelectChange"
          >
            <a-select-option value="102011">
              普通用户
            </a-select-option>
            <a-select-option value="102001">
              管理员
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      </div>
    </a-modal>

    <a-modal
      title="重置密码"
      :visible="userCountResetPwd"
      :confirm-loading="confirmLoadingAdd"
      @ok="resetPwdModalOk"
      @cancel="resetPwdModalCancel"

    >
      <div>
        <a-form :form="newPwdForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
          <a-form-item label="新密码">
          <a-input
            placeholder="请填写新密码"
            v-decorator="['newPwd', { rules: [{ required: true, message: '请填写新密码!' }] }]"
          />
        </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import {
  getUserInfoList,
  addEnterpriseAccount,
  resetUserPwd,
  freezeEnterpriseAccount,
  unLock,
  restPwd2,
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width:200
        },
        {
          title: '姓名',
          dataIndex: 'userName',
          width:100
        },
        {
          title: '账号',
          dataIndex: 'accountName',
          width:150
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          width:300
        },
        {
          title: '账号类型',
          dataIndex: 'accountType',
          customRender: text => this.accountType[text],
          width:150
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text==='100201' ? '正常' : '冻结',
          width:100
        },
        {
          title: '权限',
          dataIndex: 'roleType',
          customRender: text => text==='102011' ? '普通用户' : '管理员',
          width:100
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width:200,
           fixed: 'right'
        }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      newPwdForm: this.$form.createForm(this, { name: 'coordinated' }),
      radioValue:100701,
      accountType:{
        '100701':'普通账号',
        '100702':'邮箱账号',
      },
      userCountResetPwd:false,
      currentItem:{},
    }
  },
  methods: {
    resetPwdModalOk(){
      this.newPwdForm.validateFields((err, values) => {
        
        if (!err) {
          this.confirmLoadingAdd = true
          values.id = this.currentItem.id;
         
          // console.log('values',values);
          restPwd2(values).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.raskTable.refresh(true)
              this.resetPwdModalCancel()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    resetPwdModalCancel(){
      this.newPwdForm.resetFields();
      this.userCountResetPwd = false;
    },
    radioChange(e){
      console.log(e.target.value);
      if(e.target.value == 100702){
        this.form.resetFields()
      }
    },
    // 获取列表
    raskData (parameter) {
      return getUserInfoList(Object.assign(parameter)).then(res => {
        // res.result.data = res.result.records
        return res.result
      })
    },
    // 添加
    handleOkAdd (e) {
      // console.log(e);
      e.preventDefault();
      this.form.validateFields((err, values) => {
        
        if (!err) {
          this.confirmLoadingAdd = true
          
          if(this.radioValue == 100701){
            values.roleType = '102011';
            values.accountType = '100701';
          }
          
          if(this.radioValue == 100702){
            values.accountType = '100702';
          }

          // console.log('values',values);
          addEnterpriseAccount(values).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.raskTable.refresh(true)
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.visibleAdd = false
    },
    // 冻结
    freeze (record) {
      let that = this
      this.$confirm({
        title: '确定冻结' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseAccount({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.raskTable.refresh(true)
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 解冻
    refreeze (record) {
      let that = this
      this.$confirm({
        title: '确定解冻' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            unLock({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 重置密码
    reset (record) {
      if(record.accountType == null || record.accountType == '100702'){

        let that = this
        this.$confirm({
          title: '确定重置' + record.userName + '的密码?',
          onOk() {
            return new Promise((resolve, reject) => {
              resetUserPwd({id:record.id}).then(res => {
                if (res.success) {
                  that.$message.success(res.message)
                  that.$refs.raskTable.refresh(true)
                  resolve()
                } else {
                  that.$message.error(res.message)
                  resolve()
                }
              })
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      }
      if(record.accountType == '100701'){
        // 普通账号
        this.currentItem = record;
        this.userCountResetPwd = true;
      }
    },
  }
}
</script>
<style scoped lang="less">
.radio-choose{
  padding-bottom: 20px;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  span{
    color: #283039;
    font-size: 18px;
    font-weight: bold;
  }
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
</style>